<div mat-dialog-title>
    <span>{{ "TROUBLESHOOTING" | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<mat-dialog-content>
    <header>
        <span>{{ "TROUBLESHOOT_HEADER_1" | translate }}</span>
        @switch (stepper.selectedIndex) {
        @case (0) {
        <span>{{ "TROUBLESHOOT_SUBHEADER_STEP_1_AND_2" | translate }}</span>
        }
        @case (1) {
        <span>{{ "TROUBLESHOOT_SUBHEADER_STEP_1_AND_2" | translate }}</span>
        }
        @case (2) {
        <span>{{ "TROUBLESHOOT_SUBHEADER_STEP_3" | translate }}</span>
        }
        @case (3) {
        <span>{{ "TROUBLESHOOT_SUBHEADER_STEP_4" | translate }}</span>
        }
        }
    </header>
    <mat-stepper linear
                 #stepper
                 (selectedIndexChange)="onSelectedIndexChange($event)">
        <!-- Step 1 -->
        <mat-step [stepControl]="sourceStep">
            <form [formGroup]="sourceStep">
                <ng-template matStepLabel>{{ "TROUBLESHOOT_STEP_1_TITLE" | translate }}</ng-template>
                <mat-radio-group formControlName="source" class="radio">
                    <mat-radio-button [value]="Source.asset">
                        {{ "TITLE_ASSETS" | translate }}
                    </mat-radio-button>
                    <mat-radio-button [value]="Source.rights">
                        {{ "MY_ROLES" | translate }}
                    </mat-radio-button>
                    <mat-radio-button [value]="Source.tracker">
                        {{ "TITLE_DEVICES" | translate }}
                    </mat-radio-button>
                    <mat-radio-button [value]="Source.kpi">
                        {{ "KPI_TAB" | translate }}
                    </mat-radio-button>
                </mat-radio-group>
                <footer>
                    <button mat-flat-button
                            color="primary"
                            matStepperNext
                            [disabled]="sourceStep.invalid">
                        {{ "NEXT" | translate }}
                    </button>
                </footer>
            </form>
        </mat-step>
        <!-- Step 2 -->
        <mat-step [stepControl]="issueStep">
            <form [formGroup]="issueStep">
                <ng-template matStepLabel>{{ "TROUBLESHOOT_STEP_2_TITLE" | translate }}</ng-template>
                <mat-radio-group formControlName="issue" class="radio">
                    @for (issue of issues(); track issue) {
                        <mat-radio-button [value]="issue">
                            {{ "TROUBLESHOOT.STEP2." + (sourceStep.value.source | uppercase) + "." + (issue.type | uppercase) + ".QUESTION" + issue.index | translate }}
                        </mat-radio-button>
                    }
                    <mat-radio-button [value]="{ type: IssueType.other }">
                        {{ "TROUBLESHOOT.STEP2.NOT_OF_ASKED_QUESTION" | translate }}
                    </mat-radio-button>
                </mat-radio-group>
                <footer>
                    <button mat-flat-button
                            color="primary"
                            matStepperPrevious>
                        {{ "PREVIOUS" | translate }}
                    </button>
                    <button mat-flat-button
                            color="primary"
                            matStepperNext
                            [disabled]="issueStep.invalid">
                        {{ "NEXT" | translate }}
                    </button>
                </footer>
            </form>
        </mat-step>
        <!-- Step 3 -->
        <mat-step [stepControl]="resolutionStep">
            <ng-template matStepLabel>{{ "TROUBLESHOOT_STEP_3_TITLE" | translate }}</ng-template>
            <form [formGroup]="resolutionStep">
                @if (issueStep.value.issue?.type === IssueType.cache) {
                <mat-checkbox formControlName="context"
                              color="primary">
                    {{ "TROUBLESHOOT.STEP3.SOLVING_CONTEXT" | translate }}
                </mat-checkbox>
                @if (resolutionStep.value.context) {
                <span class="solution">
                    <mat-icon>emoji_objects</mat-icon>
                    {{ "TROUBLESHOOT.STEP3.SOLVING_CACHING" | translate }}
                </span>
                }
                }
                @for (solution of getSolutions(); track $index) {
                <span class="solution">
                    <mat-icon>emoji_objects</mat-icon>
                    <span class="solution-i18n" [innerHTML]="solution | translate"></span>
                </span>
                }
                <footer>
                    @if (stepper.selectedIndex === stepper.steps.length - 1 && getSolutions().length > 0) {
                    <ng-container *ngTemplateOutlet="satisfaction" />
                    }
                    <span class="spacer"></span>
                    <button mat-flat-button
                            color="primary"
                            matStepperPrevious>
                        {{ "PREVIOUS" | translate }}
                    </button>
                    @if (stepper.steps.length > 3) {
                    <button mat-flat-button
                            color="primary"
                            matStepperNext
                            [disabled]="resolutionStep.invalid">
                        {{ "NEXT" | translate }}
                    </button>
                    } @else {
                    <button mat-flat-button
                            color="primary"
                            mat-dialog-close>
                        {{ "CLOSE" | translate }}
                    </button>
                    }
                </footer>
            </form>
        </mat-step>
        <!-- Step 4 -->
        @if (issueStep.value.issue?.type === IssueType.cache && resolutionStep.valid) {
        <mat-step>
            <ng-template matStepLabel>{{ "TROUBLESHOOT_STEP_4_TITLE" | translate }}</ng-template>
            <div class="processing-step">
                <img src="assets/img/svg/undraw_data-processing_z2q6.svg"
                     class="img-resolution">
                @if (resolutionInProgress) {
                <span>{{ "TROUBLESHOOTING.RESOLUTION.IN_PROGRESS" | translate }}</span>
                <mat-progress-bar color="primary"
                                  [value]="resolutionProgress()"
                                  mode="buffer" />
                } @else {
                <span>{{ "TROUBLESHOOTING.RESOLUTION.COMPLETE" | translate }}</span>
                }
            </div>
            <footer>
                @if (!resolutionInProgress) {
                <ng-container *ngTemplateOutlet="satisfaction" />
                }
                <span class="spacer"></span>
                <button mat-flat-button
                        color="primary"
                        matStepperPrevious>
                    {{ "PREVIOUS" | translate }}
                </button>
                <button mat-flat-button
                        color="primary"
                        mat-dialog-close
                        [disabled]="resolutionInProgress">
                    {{ "CLOSE" | translate }}
                </button>
            </footer>
        </mat-step>
        }
    </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions>
    <a mat-button
       color="primary"
       [href]="serviceNowLink"
       target="_blank">
        {{ "TROUBLESHOOTING_CHECK_SNOW" | translate }}
    </a>
    <button mat-button
            color="primary"
            mat-dialog-close>
        Close
    </button>
</mat-dialog-actions>

<ng-template #satisfaction>
    @if (!this.feedbackGiven()) {
        <span>{{ "TROUBLESHOOT.ASK_HELPFUL" | translate }}</span>
        <button mat-button
                (click)="feedback(false)"
                color="warn">
            <mat-icon>thumb_down</mat-icon>
            {{ "NO" | translate }}
        </button>
        <button mat-button
                (click)="feedback(true)"
                color="primary">
            <mat-icon>thumb_up</mat-icon>
            {{ "YES" | translate }}
        </button>
    } @else {
        <span>{{ 'TROUBLESHOOTING.FEEDBACK_THANKS' | translate }}</span>
    }
</ng-template>
