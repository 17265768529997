import { Company } from 'src/app/models/company';
import * as CompanyAction from '../actions/company.actions';
import { createReducer, on, Action } from '@ngrx/store';
export interface State {
    data: Company[];
    loading: boolean;
    loaded: boolean;
}

export const INIT_STATE: State = {
    data: [],
    loading: false,
    loaded: false,
};

const CompaniesReducer = createReducer(
    INIT_STATE,
    on(CompanyAction.fetchAllCompanies, (state) => ({
        ...state,
        loaded: false,
    })),
    on(CompanyAction.successFetchAllCompanies, (state, { payload }) => ({
        ...state, loaded: true, data: (() => {
            return payload;
        })()
    })),
    on(CompanyAction.successCreateCompanies, (state, { payload }): State => ({
        ...state,
        data: [...state.data, ...payload]
    })),
    on(CompanyAction.successUpdateCompanies, (state, action) => ({
        ...state,
        data: (() => {
            const datas = [...state.data];
            const arrayCompanyToUpdate = action;
            for (const company of arrayCompanyToUpdate.payload) {
                const companyIndex = datas.findIndex(_company => _company.id === company.id);
                if (companyIndex > -1) {
                    datas[companyIndex] = company;
                }
            }
            return datas;
        })()
    })),
    on(CompanyAction.successDeleteCompanies, (state, { payload }) => ({
        ...state,
        data: (() => {
            const datas = [...state.data];
            const arrayCompanyToDelete = payload;

            for (const company of arrayCompanyToDelete) {
                const companyIndex = datas.findIndex(_company => _company.id === company.id);
                if (companyIndex > -1) {
                    datas.splice(companyIndex, 1);
                }
            }
            return datas;
        })()
    })),
    on(CompanyAction.errorFetchAllCompanies, (state): State => ({ ...state }))
);

export function reducer(state: State | undefined, action: Action) {
    return CompaniesReducer(state, action);
}
