import { HostListener, Injectable } from '@angular/core';
import { filter, from, Observable, of, shareReplay, Subject } from 'rxjs';

export interface LocalStorageEntry {
    key: string;
    value: string | null;
    oldValue?: string;
}

export enum ConfigurationStorageKeys {
    displaySatellite = 'map.switch_map',
    displayZone = 'map.display_zones',
    displayBeacons = 'map.display_beacons',
    displayAnchors = 'map.display_anchors',
    displayIndoor = 'map.display_indoor',
    displayAssetLabels = 'map.display_asset_label',
    masterData = 'master_data',
    mapData = 'map_data',
    wcagSFI = 'wcag-sfi',
    language = 'lang',
    dateFormat = 'date_format',
    troubleshootTutorial = "tuto_troubleshoot"
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private _storageValue$ = new Subject<LocalStorageEntry>();
    public storageValue$ = this._storageValue$.asObservable().pipe(
        shareReplay(),
    );
    public lastStorageValueByKey$: Partial<Record<ConfigurationStorageKeys, Observable<LocalStorageEntry>>> =
        Object.values(ConfigurationStorageKeys).reduce(
            (map, key) => {
                map[key] = this.storageValue$.pipe(
                    filter(entry => entry.key === key),
                    shareReplay(1),
                )
                return map;
            }, {}
        );

    @HostListener('document:storage') storageChanges(event: StorageEvent) {
        this._storageValue$.next({
            key: event.key,
            value: event.newValue,
            oldValue: event.oldValue,
        });
    }

    constructor() {
        this.fetchLocalStorageValues$().subscribe();
    }

    /**
     * Read the local storage and emits the entries
     * @returns The entries from the local storage
     */
    public fetchLocalStorageValues$(): Observable<LocalStorageEntry> {
        const stored: LocalStorageEntry[] = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const entry = { key, value: localStorage.getItem(key) };
            stored.push(entry);
            this._storageValue$.next(entry);
        }
        return from(stored);
    }

    /**
     * Read on value from the local storage
     */
    public fetchLocalStorageValueByKey$(key: ConfigurationStorageKeys): Observable<LocalStorageEntry> {
        const value = localStorage.getItem(key);
        return of({ key, value });
    }

    /**
     * Store a value in the local storage. Overwrite the value if the entry is already stored.
     * @param key The key
     * @param value The value to store
     * @returns The stored entry
     */
    public store$(key: string, value: string): Observable<LocalStorageEntry> {
        localStorage.setItem(key, value);
        this._storageValue$.next({ key, value });
        return of({ key, value });
    }

    /**
     * Add a value to an already existing key.
     * @param key The key
     * @param value The value to add to the already existing entry
     * @param separator The separator
     * @returns The stored entry
     * @throws {ReferenceError} No entry exists in the local storage for the given key
     */
    public addToKey$(key: string, value: string, separator?: string): Observable<LocalStorageEntry> {
        const storedValue = localStorage.getItem(key);
        if (storedValue === null) {
            throw new ReferenceError(`The key '${key}' does not exists in the local storage`);
        }
        value = `${storedValue}${separator ?? ''}${value}`;
        return this.store$(key, value);
    }

    /**
     * Remove a key from the local storage
     * @param key The key to remove
     * @returns The removed key
     */
    public remove$(key): Observable<string> {
        localStorage.removeItem(key);
        this._storageValue$.next({ key, value: null });
        return of(key);
    }

}
