import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as featureActions from '../actions/familyRight.action';
import { TranslateService } from '@ngx-translate/core';
import { FamilyService } from 'src/app/services/family.service';
import { CacheService } from 'src/app/services/cache.service';
import { Observable, of, combineLatest, Subject, from } from 'rxjs';
import { Store } from '@ngrx/store';
import { ObjectStore } from 'src/app/models/cache';
import * as userRightFeatureActions from '../actions/user-right.action';

export type EffectResult = 'success' | 'error';
@Injectable()
export class FamilyRightEffects {

    public LoadFamilyRight$: Observable<featureActions.Actions> = createEffect(() => {
        return this._actions$.pipe(
            ofType<featureActions.LoadFamilyRight>(featureActions.ActionTypes.LOAD_FAMILY_RIGHT),
            switchMap(() =>
                from(this._cacheService.resetDatabase(false, ObjectStore.FamilyRight)).pipe(
                    switchMap(() => this._cacheService.retrieveCacheStatus$()),
                    map(status => status.familyRight?.lastRefresh),
                    switchMap(lastRefresh => this._familyService.getFamiliesRights(lastRefresh)),
                    switchMap(rights => combineLatest([
                        this._cacheService.deleteData$(ObjectStore.FamilyRight, ...(rights.deletedData ?? []).map(({ id }) => id)),
                        this._cacheService.updateData$(ObjectStore.FamilyRight, ...(rights.updatedData ?? [])),
                        this._cacheService.createData$(ObjectStore.FamilyRight, ...(rights.createdData ?? [])),
                    ])),
                    switchMap(() => this._cacheService.getData$(ObjectStore.FamilyRight)),
                    map(rights => new featureActions.SuccessFamilyRight(rights)),
                    catchError(() => of(new featureActions.ErrorFamilyRight())),
                )))
    });

    public familiesFromMyRightTree$ = createEffect(() => {
        return this._actions$.pipe(
            ofType<userRightFeatureActions.SuccessMyRightsTree>(userRightFeatureActions.ActionTypes.SUCCESS_MY_RIGHTS_TREE),
            map(({ familiesRigths }) => new featureActions.SuccessFamilyRight(familiesRigths)),
        )
    });

    public effectSubject: Subject<EffectResult>;
    constructor(
        private _familyService: FamilyService,
        private _actions$: Actions,
        public translate: TranslateService,
        private _cacheService: CacheService,
        private readonly store: Store,
    ) {
        this.effectSubject = new Subject<EffectResult>();
    }
}
