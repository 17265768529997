import { Injectable } from '@angular/core';
import { Observable, } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MasterData } from '../models/masterdata';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MasterdataService {

    constructor(
        private http: HttpClient,
        public router: Router,
        public dialog: MatDialog,
    ) { }

    public getMasterdata(): Observable<MasterData> {
        return this.http.get<MasterData>(`${environment.API_ENDPOINT}/master-data`);
    }
}
