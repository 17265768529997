import { inject, Injectable } from '@angular/core';
import { Asset } from '../models/asset';
import {
    catchError,
    EMPTY,
    filter,
    firstValueFrom,
    from,
    map,
    Observable,
    of,
    retry,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import * as moment from 'moment';
import { Family, FamilyRight, ParentFamily } from '../models/family';
import { Company } from '../models/company';
import { Site } from '../models/site';
import { Zone } from '../models/zone';
import { LoggerService } from './logger.service';
import { SnackbarComponent } from '../components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { CacheEntry, CacheItem, ObjectStore } from '../models/cache';
import { isEmpty } from 'lodash';

const CACHE_IDB_NAME = 'cacheIDB';
const CACHE_IDB_VERSION = 2;

export enum CacheErrors {
    CacheNotExists,
    Security,
    Other,
    Unknown,
    Open,
    GetTable,
    ClearFail,
    Blocked,
    UpgradeNeeded,
    InvalidState,
    NotFound,
    GetData,
    DeleteDB,
    TransactionInactive,
    ReadOnly,
    DeleteData,
}

export class CacheError extends Error {
    private _errorCode: CacheErrors;
    public get errorCode(): CacheErrors {
        return this._errorCode;
    }
    constructor(errorCode: CacheErrors) {
        super(`Error with cache, code ${errorCode}`);
        this._errorCode = errorCode;
    }
}

type StoredDataPerTable = {
    [ObjectStore.Assets]: Asset;
    [ObjectStore.ParentFamilies]: ParentFamily;
    [ObjectStore.Families]: Family;
    [ObjectStore.FamilyRight]: FamilyRight;
    [ObjectStore.Company]: Company;
    [ObjectStore.Sites]: Site;
    [ObjectStore.Zone]: Zone;
    [ObjectStore.ExpiredBy]: { id: ObjectStore, date: string };
    [ObjectStore.LastUpdatedTime]: { id: ObjectStore, date: string };
}

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private cacheAllowed = true;
    private db: IDBDatabase | null = null;
    private loggerService = inject(LoggerService);
    private snackbar = inject(SnackbarComponent);
    private translate = inject(TranslateService);

    private _initializeTable<T extends ObjectStore>(
        name: T,
        keyPath: keyof StoredDataPerTable[T],
        indexes: { key: keyof StoredDataPerTable[T], unique?: boolean }[] = [],
        transaction?: IDBTransaction
    ) {
        let objectStore: IDBObjectStore;
        if (!this.db.objectStoreNames.contains(name)) {
            objectStore = this.db.createObjectStore(name, { keyPath: keyPath as string });
        } else {
            this.loggerService.debug('CacheService', 'Table %s already exists, not created again', name);
            objectStore = (transaction ?? this.db.transaction(name, 'readwrite')).objectStore(name);
        }
        for (const index of indexes) {
            if (!objectStore.indexNames.contains(index.key as string)) {
                objectStore.createIndex(
                    index.key as string,
                    index.key as string,
                    { unique: index.unique }
                );
            } else {
                this.loggerService.debug('CacheService', 'Index %s for table %s already exists, not created again', index.key, name);
            }
        }
    }

    private _initializeAllTables(transaction?: IDBTransaction): void {
        this._initializeTable(ObjectStore.Assets, 'id', [
            { key: 'id', unique: true },
            { key: 'familyId', unique: false },
            { key: 'trackerId', unique: false },
        ], transaction);
        this._initializeTable(ObjectStore.Company, 'id', [{ key: 'id', unique: true }], transaction);
        this._initializeTable(ObjectStore.ExpiredBy, 'id', [{ key: 'id', unique: true }], transaction);
        this._initializeTable(ObjectStore.Families, 'id', [
            { key: 'id', unique: true },
            { key: 'parentFamilyId', unique: false },
            { key: 'scope', unique: false },
        ], transaction);
        this._initializeTable(ObjectStore.FamilyRight, 'id', [
            { key: 'id', unique: true },
            { key: 'parentFamilyId', unique: false },
            { key: 'scope', unique: false },
            { key: 'level', unique: false },
        ], transaction);
        this._initializeTable(ObjectStore.LastUpdatedTime, 'id', [{ key: 'id', unique: true }], transaction);
        this._initializeTable(ObjectStore.ParentFamilies, 'id', [
            { key: 'id', unique: true },
            { key: 'siteId', unique: false },
            { key: 'scope', unique: false },
        ], transaction);
        this._initializeTable(ObjectStore.Sites, 'id', [
            { key: 'id', unique: true },
            { key: 'companyId', unique: false },
        ], transaction);
        this._initializeTable(ObjectStore.Zone, 'id', [{ key: 'id', unique: true }], transaction);
    }

    public async initializeDB(force = false): Promise<void> {
        try {
            const databases = await indexedDB.databases();
            const cacheExists = databases.some(db => db.name === CACHE_IDB_NAME);
            if (cacheExists) {
                if (force) {
                    await this.deleteDatabase();
                }
            }
            // Open DB to initialize it if an upgrade is required
            return new Promise((resolve, reject) => {
                const request = indexedDB.open(CACHE_IDB_NAME, CACHE_IDB_VERSION);
                request.onerror = () => {
                    this.loggerService.error('CacheService', 'Cannot create the database', request.error);
                    reject(new CacheError(CacheErrors.Open));
                }
                request.onblocked = () => {
                    this.cacheAllowed = false;
                    this.loggerService.error(
                        'CacheService',
                        'An open connection to a database is blocking a `versionchange` transaction on the same database',
                        request.error
                    );
                    reject(new CacheError(CacheErrors.Security));
                }
                request.onupgradeneeded = () => {
                    this.db = request.result;
                    // Create tables
                    this._initializeAllTables(request.transaction);
                };
                request.onsuccess = () => {
                    resolve();
                }
            });
        } catch (error) {
            if (error instanceof DOMException) {
                switch (error.name) {
                    case 'SecurityError':
                        this.snackbar.open(this.translate.instant("CACHE_SECURITY_ERROR"), "red-snackbar");
                        this.cacheAllowed = false;
                        this.loggerService.error('CacheService', 'SecurityError - Cannot retrieve the available IDS, method is called from an opaque origin or the user has disabled storage.', error);
                        throw new CacheError(CacheErrors.Security);
                    case 'UnknownError':
                        this.loggerService.error('CacheService', 'UnknownError - Cannot retrieve the available IDS', error);
                        throw new CacheError(CacheErrors.Unknown);
                }
            } else if (error instanceof TypeError) {
                this.loggerService.error('CacheService', 'TypeError - IDB version should be greater than 0', error);
                throw new CacheError(CacheErrors.Other);
            } else {
                this.loggerService.error('CacheService', 'Cannot retrieve the available IDS', error);
                throw new CacheError(CacheErrors.Other);
            }
        }
    }

    /**
     * Read the cache database and return the state of each table
     */
    private async _getDbObjectTypeStatus(db: IDBDatabase): Promise<CacheEntry> {
        try {
            const objectTypes = [
                ObjectStore.FamilyRight,
                ObjectStore.Zone,
                ObjectStore.ParentFamilies,
                ObjectStore.Families,
                ObjectStore.Assets,
                ObjectStore.Company,
                ObjectStore.Sites
            ];

            const promises = objectTypes.map(objectType => {
                return new Promise<CacheItem>((resolve, reject) => {
                    const transaction = db.transaction([ObjectStore.ExpiredBy, ObjectStore.LastUpdatedTime], 'readonly');

                    const expiredByObjectStore = transaction.objectStore(ObjectStore.ExpiredBy);
                    const getExpiredByRequest = expiredByObjectStore.get(objectType);

                    getExpiredByRequest.onsuccess = () => {
                        const expiredDate = getExpiredByRequest.result?.date;
                        const isExpired = expiredDate ? new Date(expiredDate) <= new Date() : true;

                        if (isExpired) {
                            resolve({ isExpired: true });
                        } else {
                            const lastUpdatedTimeObjectStore = transaction.objectStore(ObjectStore.LastUpdatedTime);
                            const getLastRefreshRequest = lastUpdatedTimeObjectStore.get(objectType);

                            getLastRefreshRequest.onsuccess = () => {
                                const lastRefreshTime: string = getLastRefreshRequest.result?.date;
                                resolve({ lastRefresh: lastRefreshTime, isExpired: false });
                            };
                            getLastRefreshRequest.onerror = (error) => {
                                this.loggerService.debug('CacheService', 'Cannot get the last refresh table', error);
                                reject(new CacheError(CacheErrors.GetTable));
                            };
                        }
                    };
                    getExpiredByRequest.onerror = (error) => {
                        this.loggerService.debug('CacheService', 'Cannot get the expiration table', error);
                        reject(new CacheError(CacheErrors.GetTable));
                    };
                });
            });

            const readResults = await Promise.all(promises);
            const cache: Awaited<ReturnType<typeof this._getDbObjectTypeStatus>> = {};
            readResults.forEach((result, index) => {
                cache[objectTypes[index]] = {
                    lastRefresh: result.lastRefresh,
                    isExpired: result.isExpired
                };
            });
            return cache;
        } catch (error) {
            this.loggerService.error("Error occurred while retrieving cache:", error);
        }
    }

    /**
     * retrieving Cache from the indexDB
     * @returns
     */
    public retrieveCacheStatus$(): Observable<CacheEntry> {
        if (!this.cacheAllowed) {
            return throwError(new CacheError(CacheErrors.Security));
        }
        return this._openDB$().pipe(
            catchError(error => {
                this.loggerService.debug('CacheService', 'Cannot retrieve the cache status', error);
                return EMPTY;
            }),
            switchMap(() => from(this._getDbObjectTypeStatus(this.db))),
        );
    }


    public clearCacheContext(): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(CACHE_IDB_NAME);

            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;

                if (db.objectStoreNames.contains(ObjectStore.Assets)) {
                    db.deleteObjectStore(ObjectStore.Assets);
                }
                if (db.objectStoreNames.contains(ObjectStore.Families)) {
                    db.deleteObjectStore(ObjectStore.Families);
                }
                if (db.objectStoreNames.contains(ObjectStore.ParentFamilies)) {
                    db.deleteObjectStore(ObjectStore.ParentFamilies);
                }
            };

            request.onsuccess = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;

                const transaction = db.transaction([ObjectStore.LastUpdatedTime, ObjectStore.ExpiredBy], 'readwrite');

                const lastRefreshStore = transaction.objectStore(ObjectStore.LastUpdatedTime);
                const expiryStore = transaction.objectStore(ObjectStore.ExpiredBy);

                lastRefreshStore.delete(ObjectStore.Assets);
                lastRefreshStore.delete(ObjectStore.Families);
                lastRefreshStore.delete(ObjectStore.ParentFamilies);

                expiryStore.delete(ObjectStore.Assets);
                expiryStore.delete(ObjectStore.Families);
                expiryStore.delete(ObjectStore.ParentFamilies);

                transaction.oncomplete = () => {
                    resolve();
                };

                transaction.onerror = (event) => {
                    reject(event);
                };
            };

            request.onerror = (event) => {
                reject(event);
            };
        });
    }

    /**
     * Delete the cache database
     * @param resolverCb Resolver callback
     * @param rejectorCb Rejector callback
     */
    private _deleteDB(
        resolverCb: (value: void | PromiseLike<void>) => void,
        rejectorCb: (reason?: any) => void
    ) {
        const request = indexedDB.deleteDatabase(CACHE_IDB_NAME);
        request.onsuccess = () => {
            this.loggerService.debug('CacheService', 'Database deleted successfully');
            resolverCb();
        };
        request.onerror = () => {
            this.loggerService.error('CacheService', 'Error deleting database:', request.error);
            rejectorCb(new CacheError(CacheErrors.DeleteDB))
        };
        request.onblocked = () => {
            this.loggerService.warn('CacheService', 'Database delete request blocked', request.error);
            rejectorCb(new CacheError(CacheErrors.Security));
        };
    }

    /**
     * Delete the dababase
     */
    public async deleteDatabase(): Promise<void> {
        // Check if database exists
        const databases = await indexedDB.databases();
        if (!databases.some(db => db.name === CACHE_IDB_NAME)) {
            return;
        }
        return new Promise<void>((resolve, reject) => {
            if (this.db) {
                this.db.close();
                this.db.onclose = () => {
                    this._deleteDB(resolve, reject);
                };
            } else {
                this._deleteDB(resolve, reject);
            }
        });
    }

    /**
     * Reset the database
     */
    // public async resetDatabase(full = false, objectName?: string): Promise<void> {
    //     // Open database
    //     await firstValueFrom(this._openDB$());
    //     const { objectStoreNames } = this.db;
    //     const storesToClear = Array.from(objectStoreNames).filter(storeName =>
    //         full? full : objectName ? [objectName] : || [
    //             ObjectStore.Assets,
    //             ObjectStore.Families,
    //             ObjectStore.ParentFamilies,
    //         ].includes(storeName as ObjectStore)
    //     );
    // }
    public async resetDatabase(full: boolean = false, objectName?: ObjectStore): Promise<void> {
        // Open database
        await firstValueFrom(this._openDB$());
        const { objectStoreNames } = this.db;
        
        const storesToClear = Array.from(objectStoreNames).filter(storeName =>
            full 
                ? true  // If full is true, clear all stores
                : (objectName ? storeName === objectName : [ObjectStore.Assets, ObjectStore.Families, ObjectStore.ParentFamilies].includes(storeName as ObjectStore))
        );
    
        // Proceed with clearing the selected stores
    
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(storesToClear, 'readwrite');
            storesToClear.forEach(storeName => {
                transaction.objectStore(storeName).clear();
            });

            transaction.oncomplete = () => {
                // Now clear keys from expiredBy and lastUpdatedTime stores
                if (this.db.objectStoreNames.contains(ObjectStore.ExpiredBy) || this.db.objectStoreNames.contains(ObjectStore.LastUpdatedTime)) {
                    const cleanupTransaction = this.db.transaction([ObjectStore.ExpiredBy, ObjectStore.LastUpdatedTime], 'readwrite');
                    const expiredByStore = cleanupTransaction.objectStore(ObjectStore.ExpiredBy);
                    const lastUpdatedTimeStore = cleanupTransaction.objectStore(ObjectStore.LastUpdatedTime);

                    // Delete the corresponding keys from expiredBy and lastUpdatedTime
                    storesToClear.forEach(key => {
                        expiredByStore.delete(key);
                        lastUpdatedTimeStore.delete(key);
                    });

                    cleanupTransaction.oncomplete = () => {
                        resolve();
                    };
                    cleanupTransaction.onerror = () => {
                        this.loggerService.error(
                            'CacheService',
                            'Cannot reset the update time & expiration tables',
                            cleanupTransaction.error
                        );
                        reject(new CacheError(CacheErrors.ClearFail));
                    };
                } else {
                    resolve();
                }
            };

            transaction.onerror = () => {
                this.loggerService.error('CacheService', 'Cannot reset the database tables', transaction.error);
                reject(new CacheError(CacheErrors.ClearFail));
            };
        });
    }

    private _checkDbExists$(): Observable<boolean> {
        return new Observable<boolean>(subscriber => {
            indexedDB.databases().then(
                databases => {
                    const cacheExists = databases.some(db => db.name === CACHE_IDB_NAME);
                    if (!cacheExists) {
                        this.loggerService.debug('CacheService', `IndexedDB database '${CACHE_IDB_NAME}' does not exist.`);
                    }
                    subscriber.next(cacheExists);
                    subscriber.complete();
                }
            ).catch(
                error => {
                    if (error instanceof CacheError) {
                        subscriber.error(error);
                    }
                    if (error instanceof DOMException) {
                        switch (error.name) {
                            case 'SecurityError':
                                this.snackbar.open(this.translate.instant("CACHE_SECURITY_ERROR"), "red-snackbar");
                                this.cacheAllowed = false;
                                this.loggerService.error('CacheService', 'SecurityError - Cannot retrieve the available IDS, method is called from an opaque origin or the user has disabled storage.', error);
                                subscriber.error(new CacheError(CacheErrors.Security));
                                break;
                            case 'UnknownError':
                                this.loggerService.error('CacheService', 'UnknownError - Cannot retrieve the available IDS', error);
                                subscriber.error(new CacheError(CacheErrors.Unknown));
                                break;
                        }
                    } else if (error instanceof TypeError) {
                        this.loggerService.error('CacheService', 'TypeError - IDB version should be greater than 0', error);
                        subscriber.error(new CacheError(CacheErrors.Other));
                    } else {
                        this.loggerService.error('CacheService', 'Cannot retrieve the available IDS', error);
                        subscriber.error(new CacheError(CacheErrors.Other));
                    }
                }
            );
        })
    }

    /**
     * Open the database for cache
     * @returns The database
     */
    private _openDB$(): Observable<IDBDatabase> {
        return this._checkDbExists$().pipe(
            retry(1),
            catchError(error => {
                this.loggerService.debug('CacheService', 'Cannot check if the DB exists', error);
                return throwError(error);
            }),
            filter(exists => exists),
            switchMap(() => {
                return new Observable<IDBDatabase>(subscriber => {
                    if (this.db) {
                        subscriber.next(this.db);
                        subscriber.complete();
                    } else {
                        const request = indexedDB.open(CACHE_IDB_NAME, CACHE_IDB_VERSION);

                        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                            this.db = (event.target as IDBOpenDBRequest).result;
                            // Create multiple object stores (tables)
                            this._initializeAllTables((event.target as IDBOpenDBRequest).transaction);
                        };
                        request.onsuccess = (event) => {
                            this.db = (event.target as IDBOpenDBRequest).result;
                            subscriber.next(this.db);
                            subscriber.complete();
                        };
                        request.onerror = () => {
                            if (request.error instanceof TypeError) {
                                this.loggerService.error('CacheService', 'TypeError - IDB version should be greater than 0', request.error);
                                subscriber.error(new CacheError(CacheErrors.Other));
                            } else {
                                this.loggerService.error('CacheService', 'Cannot retrieve the available IDS', request.error);
                                subscriber.error(new CacheError(CacheErrors.Other));
                            }
                        }
                    }
                });
            }),
        );
    }

    /**
     * Update the last update time and expiration time for the given key
     * @param key The table key
     */
    private _registerLastUpdate(key: ObjectStore): void {
        // Update LastUpdatedTime
        const lastUpdatedObjectStore = this.db.transaction([ObjectStore.LastUpdatedTime], 'readwrite').objectStore(ObjectStore.LastUpdatedTime);
        const lastUpdateRequest = lastUpdatedObjectStore.put({
            id: key,
            // Current time
            date: moment().utcOffset(0).format('YYYY-MM-DDTHH:mm:ss+0000'),
        });
        lastUpdateRequest.onerror = () => {
            this.loggerService.error('CacheService', `Cannot update the last update time of ${key}`, lastUpdateRequest.error);
        };

        // Update ExpiredBy
        const expiredByObjectStore = this.db.transaction([ObjectStore.ExpiredBy], 'readwrite').objectStore(ObjectStore.ExpiredBy);
        const expirationRequest = expiredByObjectStore.put({
            id: key,
            // Expiration time
            date: moment().add(7, 'days').utcOffset(0).format('YYYY-MM-DDTHH:mm:ss+0000'),
        });
        expirationRequest.onerror = () => {
            this.loggerService.error('CacheService', `Cannot update the expiration time of ${key}`, expirationRequest.error);
        };
    }

    /**
     * Get the data stored into a table
     * @param key The table key
     * @returns The data contained in the table
     */
    public getData$<T extends ObjectStore>(key: T): Observable<Array<StoredDataPerTable[T]>> {
        return this._openDB$().pipe(
            switchMap(() => {
                return new Observable<Array<StoredDataPerTable[T]>>(
                    subscriber => {
                        const data = this.db.transaction([key], 'readonly').objectStore(key).getAll();
                        data.onsuccess = () => {
                            subscriber.next(data.result);
                        };
                        data.onerror = () => {
                            this.loggerService.error('CacheService', `Cannot get the data for the key ${key}`, data.error);
                            subscriber.error(new CacheError(CacheErrors.GetData));
                        }
                    }
                );
            }),
        );
    }

    /**
     * On Updation of an asset in by current user, Or if delta is returning any asset(s) that has been update in the db.
     * Updating them into indexDB
     * @param updatedDatas
     * @param delta
     * @returns
     */
    public updateData$<T extends ObjectStore>(
        objectType: T,
        ...updatedDatas: Array<StoredDataPerTable[T]>
    ): Observable<Array<StoredDataPerTable[T]>> {
        return this._openDB$().pipe(
            tap(() => {
                this.loggerService.debug('CacheService', 'Update %d items for %s', updatedDatas?.length, objectType);
            }),
            switchMap(() => {
                if (updatedDatas?.length > 0) {
                    return from(this._updateData(objectType, updatedDatas));
                }
                return of(null);
            }),
            map(() => {
                this._registerLastUpdate(objectType);
                return updatedDatas;
            }),
        );
    }

    /**
     * Stores new data into the cache
     * @param data The data to add in the cache table
     * @param key The key of the table
     * @returns The added data
     */
    public createData$<T extends ObjectStore>(
        key: T,
        ...data: Array<StoredDataPerTable[T]>
    ): Observable<Array<StoredDataPerTable[T]>> {
        return this._openDB$().pipe(
            tap(() => {
                this.loggerService.debug('CacheService', 'Create %d items for %s', data?.length, key);
            }),
            switchMap(() => {
                if (data?.length) {
                    return from(this._addData(key, data));
                }
                return of(null);
            }),
            map(() => {
                this._registerLastUpdate(key);
                return data;
            }),
        );
    }

    private _deleteData(store: IDBObjectStore, key: IDBValidKey): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const deleteRequest = store.delete(key);
            deleteRequest.onsuccess = () => {
                resolve();
            }
            /** Handles errors */
            deleteRequest.onerror = () => {
                this.loggerService.error(
                    'CacheService',
                    `Error deleting item with ID ${key.toString()}:`,
                    deleteRequest.error
                );
                if (deleteRequest.error instanceof DOMException) {
                    switch (deleteRequest.error.name) {
                        case 'TransactionInactiveError':
                            this.loggerService.debug(
                                'CacheService',
                                'This object store\'s transaction is inactive',
                                deleteRequest.error,
                            );
                            reject(new CacheError(CacheErrors.TransactionInactive));
                            break;
                        case 'ReadOnlyError':
                            this.loggerService.debug(
                                'CacheService',
                                'the object store\'s transaction mode is read-only.',
                                deleteRequest.error,
                            );
                            reject(new CacheError(CacheErrors.ReadOnly));
                            break;
                        case 'InvalidStateError':
                            this.loggerService.debug(
                                'CacheService',
                                'The database is already closed',
                                deleteRequest.error,
                            );
                            reject(new CacheError(CacheErrors.InvalidState));
                            break;
                        case 'DataError':
                            this.loggerService.debug(
                                'CacheService',
                                'The object store has been deleted.',
                                deleteRequest.error,
                            );
                            reject(new CacheError(CacheErrors.DeleteData));
                            break;
                    }
                } else {
                    this.loggerService.error('CacheService', 'Cannot delete item from the cache', deleteRequest.error);
                    reject(new CacheError(CacheErrors.Unknown));
                }
            };
        });
    }

    /**
     * Delete items from the cache
     * @param table.table The name of the table
     * @param table.index The index of the table on which delete the items.
     * @default table.index 'id'
     * @param deletedIds The ids to delete, based on the index
     * @returns The deleted ids (based on `id`)
     */
    public deleteData$<T extends ObjectStore>(
        table: { table: T, index?: keyof StoredDataPerTable[T] },
        ...deletedIds: Array<StoredDataPerTable[T]['id']>
    ): Observable<Array<StoredDataPerTable[T]['id']>>
    /**
     * Delete items from the cache by `id`
     * @param table The name of the table
     * @param deletedIds The ids to delete, based on the index
     * @returns The deleted ids (based on `id`)
     */
    public deleteData$<T extends ObjectStore>(
        table: T,
        ...deletedIds: Array<StoredDataPerTable[T]['id']>
    ): Observable<Array<StoredDataPerTable[T]['id']>>
    public deleteData$<T extends ObjectStore>(table, ...deletedIds) {
        let params: { table: T, index: string };
        if (typeof table === "string") {
            params = { table: table as T, index: 'id' };
        } else {
            params = structuredClone(table);
        }
        this.loggerService.debug('CacheService', 'Delete %d items for %s', deletedIds?.length, params.table);
        if (!deletedIds.length) {
            this._registerLastUpdate(params.table);
            return of([]);
        }
        return this._openDB$().pipe(
            switchMap(() => {
                return new Observable<Array<StoredDataPerTable[T]['id']>>(subscriber => {
                    const transaction = this.db.transaction([params.table], 'readwrite');
                    const store = transaction.objectStore(params.table);
                    const idIndex = store.index(params.index);
                    const idsToDelete = structuredClone(deletedIds);
                    idsToDelete.sort();
                    const deleteCursor = idIndex.openKeyCursor(
                        IDBKeyRange.bound(idsToDelete[0], idsToDelete[idsToDelete.length - 1])
                    );
                    const primaryKeys: IDBValidKey[] = []
                    deleteCursor.onsuccess = () => {
                        const cursor = deleteCursor.result;
                        if (!cursor || isEmpty(idsToDelete)) {
                            return;
                        }
                        const id = cursor.key.toString();
                        if (idsToDelete[0] !== id) {
                            // Eliminate ids before the cursor position
                            while (idsToDelete[0] < id) {
                                idsToDelete.splice(0, 1);
                            }
                            cursor.continue(idsToDelete[0]);
                        } else {
                            primaryKeys.push(cursor.primaryKey);
                            this._deleteData(store, cursor.primaryKey);
                            cursor.continue();
                        }
                    }

                    transaction.oncomplete = () => {
                        subscriber.next(primaryKeys.map(
                            key => key.toString()
                        ));
                        this._registerLastUpdate(params.table);
                    };

                    transaction.onerror = () => {
                        if (transaction.error instanceof DOMException) {
                            switch (transaction.error.name) {
                                case 'InvalidStateError':
                                    this.loggerService.debug(
                                        'CacheService',
                                        'The database is already closed',
                                        transaction.error,
                                    );
                                    subscriber.error(new CacheError(CacheErrors.InvalidState));
                                    break;
                                case 'NotFoundError':
                                    this.loggerService.debug(
                                        'CacheService',
                                        'An object store specified in the `storeNames` parameter has been deleted or removed',
                                        transaction.error,
                                    );
                                    subscriber.error(new CacheError(CacheErrors.NotFound));
                                    break;
                                case 'InvalidAccessError':
                                    this.loggerService.debug(
                                        'CacheService',
                                        'Function was called with an empty list of store names.',
                                        transaction.error,
                                    );
                                    subscriber.error(new CacheError(CacheErrors.Other));
                                    break;
                            }
                        } else {
                            this.loggerService.error('CacheService', 'Cannot delete from the cache', transaction.error);
                            subscriber.error(new CacheError(CacheErrors.Unknown));
                        }
                    };
                });
            })
        )
    }

    /**
     * Add data into a table
     * @private
     * @param tableName
     * @param objectData The data to insert
     * @memberof CacheService
     */
    private _addData<T extends ObjectStore>(tableName: T, objectData: Array<StoredDataPerTable[T]>): Promise<void> {
        if (!this.db) {
            this.loggerService.error('CacheService', 'Database not initialized.');
            return;
        }

        return new Promise<void>((resolve, reject) => {
            const transaction = this.db.transaction([tableName], 'readwrite');
            const objectStore = transaction.objectStore(tableName);

            if (Array.isArray(objectData)) {
                objectData.forEach((data) => objectStore.put(data));
            } else {
                Object.values(objectData).forEach((data) => objectStore.put(data));
            }
            transaction.oncomplete = () => {
                resolve();
            }
            transaction.onerror = () => {
                this.loggerService.error('CacheService', `Cannot add the items to ${tableName}`, transaction.error);
                reject(transaction.error);
            }
        });
    }

    /**
     * Update a data in the table*
     * @private
     * @template T The table name
     * @param tableName
     * @param updatedData The data to update
     * @memberof CacheService
     */
    private _updateData<T extends ObjectStore>(tableName: T, updatedData: Array<StoredDataPerTable[T]>): Promise<void> {
        if (!this.db) {
            this.loggerService.error('CacheService', 'Database not initialized while update.');
            return;
        }

        return new Promise<void>((resolve, reject) => {
            const transaction = this.db.transaction([tableName], 'readwrite');
            const objectStore = transaction.objectStore(tableName);

            const dataPerId = updatedData.reduce(
                (perId, data) => {
                    perId[data.id] = data;
                    return perId;
                }, {} as Record<StoredDataPerTable[T]['id'], StoredDataPerTable[T]>
            );

            // Run cursor on the table items to update them
            const idIndex = objectStore.index('id');
            const updateCursor = idIndex.openCursor();
            updateCursor.onsuccess = () => {
                const cursor = updateCursor.result;
                if (!cursor || isEmpty(dataPerId)) {
                    return;
                }
                const id = cursor.primaryKey.toString();
                if (dataPerId[id]) {
                    cursor.update(dataPerId[id]);
                    delete dataPerId[id];
                }
                cursor.continue();
            }
            updateCursor.onerror = () => {
                this.loggerService.error('CacheService', `Cannot update the items ${tableName}`, updateCursor.error);
                reject(updateCursor.error);
            }
            updateCursor.transaction.oncomplete = () => {
                // Add remaining elements
                this._addData(tableName, Object.values(dataPerId)).then(
                    () => resolve(),
                ).catch(
                    error => reject(error)
                );
            }
        })
    }
}
