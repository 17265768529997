import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import { CustomPaginator } from './utils/custom-paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import {
    MatSidenavModule,
    MatSidenavContent
} from '@angular/material/sidenav';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MtxDatetimepickerIntl, MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxMomentDatetimeModule } from '@ng-matero/extensions-moment-adapter';
import { MtxDatetimepickerI18n } from './utils/mtx-intl';
import { filter } from 'rxjs/operators';
import { LanguageService } from './services/language.service';
import { LocaleMomentDateAdapter } from './utils/locale-moment-date-adapter';
import { combineLatest } from 'rxjs';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatButtonModule,
        MatBadgeModule,
        MatSidenavModule,
        MatStepperModule,
        NgxMaskDirective,
        InfiniteScrollDirective,
        NgxMatSelectSearchModule
    ],
    exports: [
        A11yModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatDialogModule,
        MatIconModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatCardModule,
        MatCheckboxModule,
        MatRadioModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatBadgeModule,
        MatSidenavModule,
        MatSidenavContent,
        InfiniteScrollDirective,
        MatStepperModule,
        MatButtonToggleModule,
        MatTreeModule,
        ScrollingModule,
        DragDropModule,
        MatRippleModule,
        MatListModule,
        NgxMatSelectSearchModule,
        NgxMaskDirective,
        MatBottomSheetModule,
        MatProgressBarModule,
        NgxMatSelectSearchModule,
        MtxDatetimepickerModule,
        MtxMomentDatetimeModule,
        PortalModule,
        OverlayModule,
    ],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            deps: [TranslateService],
            useFactory: (translate: TranslateService) => translate.currentLang,
        },
        {
            provide: DateAdapter,
            useClass: LocaleMomentDateAdapter,
            deps: [MAT_DATE_LOCALE, LanguageService],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS,
        },
        {
            provide: MtxDatetimepickerIntl,
            useFactory: (translateService: TranslateService) => new MtxDatetimepickerI18n(translateService),
            deps: [TranslateService],
        },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new CustomPaginator(translateService).getPaginatorIntl()
        },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { autoActiveFirstOption: true } },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        provideNgxMask(),
    ]
})

export class AppMaterialModule {
    constructor(
        language: LanguageService,
        dateAdapter: DateAdapter<any>,
    ) {
        combineLatest([
            language.selectedLanguage$,
            language.selectedDateFormat$,
        ]).pipe(
            filter(([value]) => !!value),
        ).subscribe(
            ([value]) => dateAdapter.setLocale(value)
        );
    }
}
