import * as featureActions from '../actions/familyRight.action';
import { FamilyRight } from 'src/app/models/family';

export interface State {
    data: Array<FamilyRight>;
    loading: boolean;
    loaded: boolean;
}

// default state
const initialState: State = {
    data: [],
    loading: false,
    loaded: false
};

export function reducer(
    state: State = initialState,
    action: featureActions.Actions
): State {

    switch (action.type) {

        case featureActions.ActionTypes.LOAD_FAMILY_RIGHT:
            return {
                ...state,
                loading: true,
                loaded: false,
            };

        case featureActions.ActionTypes.SUCCESS_FAMILY_RIGHT:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: (() => {
                    return action.payload;
                })()
            };

        case featureActions.ActionTypes.ERROR_FAMILY_RIGHT:
            return {
                ...state,
                loading: false
            };

        case featureActions.ActionTypes.SUCCESS_UPDATE_FAMILY_RIGHT:
            return {
                ...state,
                data: (data => {
                    const right = data.find(
                        ({ id }) => id === action.payload.id
                    );
                    if (!right) {
                        data.push(action.payload);
                    } else if (right.level !== action.payload.level) {
                        right.level = action.payload.level;
                    }
                    return data;
                })(state.data),
            }
        default:
            return state;
    }
}
