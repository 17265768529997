<nav [ngClass]="{'header-mobile':mobileMenuIsOpened}" class="navbar header">

    <a *ngIf="!mobile" [ngClass]="{'mobile-content-up':mobileMenuIsOpened}"
       class="navbar-brand"
       [routerLink]="['/map', 'view-map']"
       title="{{ env.production ? 'PRODUCTION':'DEVELOPMENT'  }} | {{env.envName}}">
        <img src="assets/img/airbus_white.png" alt="Airbus logo">
    </a>

    <div [ngClass]="{'mobile-content-up':mobileMenuIsOpened}" class="nav navbar-nav">

        <a class="my-auto" [routerLink]="['/map', 'view-map']"
            aria-label="way">
            {{ 'APP_TITLE' | translate }}
        </a>

        <div class="main-header-container">

            <!-- Desktop navigation menu -->
            <div>
                <div *ngIf="!mobile">
                    <nav class="menu">
                        <a matRipple class="menu-item" [routerLink]="['/map', 'view-map']" routerLinkActive="nav-active">
                            <span>{{ 'APP_TAB_MAP' | translate }}</span>
                        </a>

                        <button matRipple class="menu-item" [ngClass]="[
                            router.isActive('/map/view-table', false) ? 'nav-active' : '',
                            router.isActive('/map/zones-view-table', false) ? 'nav-active' : '']"
                            [matMenuTriggerFor]="menuMapViewTable"
                            #mapTableMenuTrigger="matMenuTrigger"
                            [appOpenMenuHover]="mapTableMenuTrigger">
                            <span class="menu-item--title">{{ 'APP_TAB_TRACKING' | translate }}</span>
                        </button>

                        <button matRipple *ngIf="isOversize || isAct" class="menu-item" [ngClass]="[
                            router.isActive('/planning', false) ? 'nav-active' : '',
                            router.isActive('/aircraft-planning', false) ? 'nav-active' : '']"
                            [matMenuTriggerFor]="menuPlanning"
                            #planningMenuTrigger="matMenuTrigger"
                            [appOpenMenuHover]="planningMenuTrigger">
                            <span class="menu-item--title">{{ 'APP_TAB_PLANNING' | translate }}</span>
                        </button>

                        <button matRipple class="menu-item" [ngClass]="[
                            router.isActive('/kpi', false) ? 'nav-active' : '',
                            router.isActive('/usage-metrics', false) ? 'nav-active' : '']"
                            [matMenuTriggerFor]="menuAnalytics"
                            #analyticsMenuTrigger="matMenuTrigger"
                            [appOpenMenuHover]="analyticsMenuTrigger">
                            <span class="menu-item--title">{{ 'KPI_TAB' | translate }}</span>
                        </button>

                        <a matRipple class="menu-item" [routerLink]="['/alert']" routerLinkActive="nav-active">
                            <span class="menu-item--title">{{ 'APP_TAB_ALERT' | translate }}</span>
                        </a>

                        <button matRipple class="menu-item" [ngClass]="[
                            router.isActive('/admin/assets', false) ? 'nav-active' : '',
                            router.isActive('/admin/families', false) ? 'nav-active' : '',
                            router.isActive('/admin/devices', false) ? 'nav-active' : '',
                            router.isActive('/admin/search-devices', false) ? 'nav-active' : '',
                            router.isActive('/admin/anchors', false) ? 'nav-active' : '',
                            router.isActive('/admin/zones', false) ? 'nav-active' : '']"
                            [matMenuTriggerFor]="menuAdmin"
                            #adminMenuTrigger="matMenuTrigger"
                            [appOpenMenuHover]="adminMenuTrigger">
                            <span class="menu-item--title">{{ 'APP_TAB_ADMIN' | translate }}</span>
                        </button>

                        <mat-menu #menuMapViewTable="matMenu">
                            <div matMenuContent
                                 cdkTrapFocus
                                 (keydown.tab)="$event.stopPropagation()"
                                 (keydown.shift.tab)="$event.stopPropagation()">
                                <a mat-menu-item
                                   [routerLink]="['/map', 'view-table']">
                                    {{ 'APP_TAB_TRACKING_ASSET' | translate }}</a>
                                <a mat-menu-item
                                   [routerLink]="['/map', 'zones-view-table']">
                                    {{ 'APP_TAB_TRACKING_ZONE' | translate }}</a>
                            </div>
                        </mat-menu>

                        <mat-menu #menuAdmin="matMenu">
                            <div matMenuContent
                                 cdkTrapFocus
                                 (keydown.tab)="$event.stopPropagation()"
                                 (keydown.shift.tab)="$event.stopPropagation()">
                                <a mat-menu-item
                                   [routerLink]="['/admin/assets']">
                                   {{ 'APP_TAB_ADMIN_ASSET' | translate }}
                                </a>
                                <a mat-menu-item
                                   [routerLink]="['/admin/families']">
                                   {{ 'APP_TAB_ADMIN_FAMILIES' | translate }}
                                </a>
                                <a mat-menu-item
                                   [routerLink]="['/admin/devices']">
                                   {{ 'APP_TAB_ADMIN_DEVICES' | translate }}
                                </a>
                                <a mat-menu-item
                                   [routerLink]="['/admin/search-devices']">
                                   {{ 'APP_TAB_ADMIN_ALL_DEVICES' | translate }}
                                </a>
                                <a mat-menu-item
                                   [routerLink]="['/admin/anchors']">
                                   {{ 'APP_TAB_ADMIN_ANCHORS' | translate }}
                                </a>
                                <a mat-menu-item
                                   [routerLink]="['/admin/zones']">
                                   {{ 'APP_TAB_ADMIN_ZONES' | translate}}
                                </a>
                            </div>
                        </mat-menu>

                        <mat-menu #menuPlanning="matMenu">
                            <div matMenuContent
                                 cdkTrapFocus
                                 (keydown.tab)="$event.stopPropagation()"
                                 (keydown.shift.tab)="$event.stopPropagation()">
                                <a *ngIf="isOversize"
                                   mat-menu-item
                                   [routerLink]="['/planning']">
                                    {{ 'APP_TAB_PLANNING' | translate }}
                                </a>
                                <!-- Remove conditions on envName when testing has been done or for local dev -->
                                <a *ngIf="isAct"
                                   mat-menu-item
                                   [routerLink]="['/aircraft-planning']">
                                    {{ 'APP_TAB_AIRCRAFT_PLANNING' | translate }}
                                </a>
                            </div>
                        </mat-menu>

                        <mat-menu #menuAnalytics="matMenu">
                            <div matMenuContent
                                 cdkTrapFocus
                                 (keydown.tab)="$event.stopPropagation()"
                                 (keydown.shift.tab)="$event.stopPropagation()">
                                <a mat-menu-item
                                   [routerLink]="['/kpi']">
                                    {{ 'KPI_TAB' | translate }}
                                </a>
                                <a *ngIf="isAdmin"
                                   mat-menu-item
                                   [routerLink]="['/usage-metrics']">
                                    {{ 'APP_TAB_USAGE_METRICS' | translate }}
                                </a>
                            </div>
                        </mat-menu>
                    </nav>
                </div>
            </div>

            <!-- <button id="troubleshoot-button"
                    mat-icon-button
                    class="menu-item--title"
                    [matTooltip]="'TROUBLESHOOTING' | translate"
                    (click)="troubleshoot()">
                <mat-icon>troubleshoot</mat-icon>
            </button> -->

            <button id="language-selection"
                    mat-icon-button
                    class="menu-item--title"
                    (click)="openLanguageSelection($event)"
                    [attr.aria-label]="'SELECT_LANGUAGE' | translate">
                <mat-icon>translate</mat-icon>
            </button>

            <!-- Icon linked to menu -->
            <div>
                <button mat-icon-button
                        class="header-action-icon"
                        [matMenuTriggerFor]="!mobile ? menu : null"
                        #helpMenuTrigger="matMenuTrigger"
                        [appOpenMenuHover]="!mobile ? helpMenuTrigger : null"
                        menuCloseDelay="250"
                        (click)="mobile ? openMenuMobile() : null">
                    <fa-icon icon="question-circle"></fa-icon>
                </button>
                <mat-menu #menu="matMenu"
                          class="help-menu">
                    <div matMenuContent
                         cdkTrapFocus
                         (keydown.tab)="$event.stopPropagation()"
                         (keydown.shift.tab)="$event.stopPropagation()">
                        <ng-container *ngFor="let item of helpListService.HELP_LIST">
                            <ng-container *ngIf="item.link; else buttonTemplate">
                                <a mat-menu-item
                                   [href]="item.link"
                                   target="_blank"
                                   rel="noopener">
                                    <mat-icon>{{ item.icon }}</mat-icon>
                                    <div class="item-content">
                                        <span class="headline">{{ item.titleI18n | translate }}</span>
                                        <span class="subtitle">{{ item.subtitleI18n | translate }}</span>
                                    </div>
                                </a>
                            </ng-container>
                            <ng-template #buttonTemplate>
                                <button mat-menu-item
                                        (click)="item.callback()">
                                    <mat-icon>{{ item.icon }}</mat-icon>
                                    <div class="item-content">
                                        <span class="headline">{{ item.titleI18n | translate }}</span>
                                        <span class="subtitle">{{ item.subtitleI18n | translate }}</span>
                                    </div>
                                </button>
                            </ng-template>
                        </ng-container>
                    </div>
                </mat-menu>
            </div>

            <!-- Websocket management -->
            <div>
                <button mat-icon-button
                        [attr.aria-label]="'ARIA_WEBSOCKET_MANAGEMENT' | translate"
                        [matTooltip]="(webSocketRealTime ? 'WEBSOCKET_CONNECTED_TOOLTIP' : 'WEBSOCKET_DISCONNECTED_TOOLTIP') | translate"
                        (click)="showWebSocketInfo()">
                    <fa-icon *ngIf="webSocketRealTime"
                             icon="history"
                             animation="fade"></fa-icon>
                    <fa-icon *ngIf="!webSocketRealTime"
                             icon="history"
                             disabled></fa-icon>
                </button>

            </div>

            <!-- Mobile icon replacing menu -->

            <nav class="menu"
                 *ngIf="decodedToken">
                    <button *ngIf="!mobile"
                            matRipple
                            class="menu-item"
                            [ngClass]="[
                                router.isActive('/rights/request', false) ? 'nav-active' : '',
                                router.isActive('/rights/manage-requests', false) ? 'nav-active' : '',
                                router.isActive('/rights/manage-user-roles', false) ? 'nav-active' : '',
                                router.isActive('/rights/manage-family-users', false) ? 'nav-active' : '',
                                router.isActive('/rights/groups', false) ? 'nav-active' : '',
                                router.isActive('/certificates', false) ? 'nav-active' : '',
                                router.isActive('/dev-tools', false) ? 'nav-active' : ''
                            ]"
                            [attr.aria-label]="'ARIA_USER_MANAGEMENT' | translate"
                            [matMenuTriggerFor]="profileMenu"
                            #rightMenuTrigger="matMenuTrigger"
                            [appOpenMenuHover]="rightMenuTrigger">
                        <span class="menu-item--title">
                            <mat-icon>person</mat-icon>
                            <span>{{ decodedToken.family_name }} {{ decodedToken.given_name }}</span>
                        </span>
                    </button>

                    <mat-menu #profileMenu="matMenu">
                        <div matMenuContent
                            cdkTrapFocus
                            (keydown.tab)="$event.stopPropagation()"
                            (keydown.shift.tab)="$event.stopPropagation()">
                            <a mat-menu-item
                                [routerLink]="['/rights/request']">
                                {{"MY_ROLES" | translate}}
                            </a>
                            <button *ngIf="isManager || isAdmin"
                                    mat-menu-item
                                    [matMenuTriggerFor]="profileSubMenu"
                                    #usersRoleMenuTrigger="matMenuTrigger"
                                    [appOpenMenuHover]="usersRoleMenuTrigger">
                                {{"MANAGE_USERS_ROLES" | translate}}
                            </button>
                            <a *ngIf="isGroupAdmin || isAdmin"
                                mat-menu-item
                                [routerLink]="['/rights/groups']">
                                {{ "MANAGE_GROUPS" | translate }}
                            </a>
                            <a *ngIf="isOversize"
                                mat-menu-item
                                [routerLink]="['/certificates']">
                                {{"CERTIFICATES" | translate}}
                            </a>
                            <a *ngIf="!env.production"
                                mat-menu-item
                                [routerLink]="['/dev-tools']">
                                {{"DEV_TOOLS" | translate}}
                            </a>
                        </div>
                    </mat-menu>

                    <mat-menu #profileSubMenu="matMenu">
                        <div matMenuContent
                            cdkTrapFocus
                            (keydown.tab)="$event.stopPropagation()"
                            (keydown.shift.tab)="$event.stopPropagation()">
                            <a *ngIf="isManager"
                                mat-menu-item
                                [routerLink]="['/rights/manage-requests']">
                                {{"MANAGE_RIGHT_REQUEST" | translate}}
                            </a>
                            <a *ngIf="isManager"
                                mat-menu-item
                                [routerLink]="['/rights/manage-user-roles']">
                                {{"MANAGE_RIGHT_TO_USER" | translate}}
                            </a>
                            <a *ngIf="isManager"
                                mat-menu-item
                                [routerLink]="['/rights/manage-family-users']">
                                {{ "MANAGE_FAMILY_USERS" | translate }}
                            </a>
                        </div>
                    </mat-menu>

                <button id="user-info"
                        mat-icon-button
                        [attr.aria-label]="'ARIA_USER_INFO_AND_A11Y' | translate"
                        (click)="openUserInfo()">
                    <fa-icon icon="id-card-clip"></fa-icon>
                </button>
            </nav>

            <button *ngIf="mobile"
                mat-icon-button
                (click)="mobileMenuIsOpened = !mobileMenuIsOpened">
                <mat-icon [@rotate360]="mobileMenuIsOpened ? 'rotate' : 'void'">
                    {{ mobileMenuIsOpened ? 'close' : 'menu' }}
                </mat-icon>
            </button>
        </div>
    </div>
</nav>

<!-- TODO: remove -->
<mat-menu #menuTranslate="matMenu">
    <div matMenuContent
         cdkTrapFocus
         (keydown.tab)="$event.stopPropagation()"
         (keydown.shift.tab)="$event.stopPropagation()">
         <div class="description-popup">
            <span>{{ "LANGUAGE_SELECTION_MOVED" | translate }}</span>
            <span>{{ "LANG_CLICK_ON_ICON" | translate }}&nbsp;&colon;&nbsp;<fa-icon icon="id-card-clip"></fa-icon></span>
        </div>
    </div>
</mat-menu>

<!-- Mobile navigation menu -->
<div class="menu-mobile"
     *ngIf="mobile"
     [@detailExpand]="mobileMenuIsOpened ? 'expanded' : 'collapsed'"
     (click)="mobileMenuIsOpened = false">
    <mat-nav-list>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header (click)="$event.stopPropagation()">
                    {{ 'APP_TAB_TRACKING_ASSET' | translate }}
                </mat-expansion-panel-header>
                <a mat-list-item
                   [routerLink]="['/map', 'view-map']">
                    {{ 'APP_TAB_MAP' | translate }}
                </a>
                <a mat-list-item
                   [routerLink]="['/map', 'view-table']">
                    {{ 'APP_TAB_TRACKING_ASSET' | translate }}
                </a>
                <a mat-list-item
                   [routerLink]="['/map', 'zones-view-table']">
                    {{ 'APP_TAB_TRACKING_ZONE' | translate }}
                </a>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header (click)="$event.stopPropagation()">
                    {{ 'APP_TAB_PLANNING' | translate }}
                </mat-expansion-panel-header>
                <a mat-list-item
                   *ngIf="isOversize"
                   [routerLink]="['/planning']">
                    {{ 'APP_TAB_PLANNING' | translate }}
                </a>
                <a mat-list-item
                   *ngIf="isAct"
                   [routerLink]="['/aircraft-planning']">
                    {{ 'APP_TAB_AIRCRAFT_PLANNING' | translate }}
                </a>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header (click)="$event.stopPropagation()">
                    {{ 'KPI_TAB' | translate }}
                </mat-expansion-panel-header>
                <a mat-list-item
                   [routerLink]="['/kpi']">
                    {{ 'KPI_TAB' | translate }}
                </a>
                <a mat-list-item
                   *ngIf="isAdmin"
                   [routerLink]="['/usage-metrics']">
                    {{ 'APP_TAB_USAGE_METRICS' | translate }}
                </a>
            </mat-expansion-panel>
            <a mat-list-item
               [routerLink]="['/alert']">
                {{ 'APP_TAB_ALERT' | translate }}
            </a>
            <mat-expansion-panel>
                <mat-expansion-panel-header (click)="$event.stopPropagation()">
                    {{ 'APP_TAB_ADMIN' | translate }}
                </mat-expansion-panel-header>
                <a mat-list-item
                   [routerLink]="['/admin/assets']">
                    {{ 'APP_TAB_ADMIN_ASSET' | translate }}
                </a>
                <a mat-list-item
                   [routerLink]="['/admin/families']">
                    {{ 'APP_TAB_ADMIN_FAMILIES' | translate }}
                </a>
                <a mat-list-item
                   [routerLink]="['/admin/devices']">
                    {{ 'APP_TAB_ADMIN_DEVICES' | translate }}
                </a>
                <a mat-list-item
                   [routerLink]="['/admin/search-devices']">
                    {{ 'APP_TAB_ADMIN_ALL_DEVICES' | translate }}
                </a>
                <a mat-list-item
                   [routerLink]="['/admin/anchors']">
                    {{ 'APP_TAB_ADMIN_ANCHORS' | translate }}
                </a>
                <a mat-list-item
                   [routerLink]="['/admin/zones']">
                    {{ 'APP_TAB_ADMIN_ZONES' | translate }}
                </a>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="decodedToken">
                <mat-expansion-panel-header (click)="$event.stopPropagation()">
                    {{ "RIGHTS_MANAGEMENT" | translate }}
                </mat-expansion-panel-header>
                <a mat-list-item
                   [routerLink]="['/rights/request']">
                    {{"MY_ROLES" | translate}}
                </a>
                <a *ngIf="isManager"
                   mat-list-item
                   [routerLink]="['/rights/manage-requests']">
                    {{"MANAGE_RIGHT_REQUEST" | translate}}
                </a>
                <a *ngIf="isManager"
                   mat-list-item
                   [routerLink]="['/rights/manage-user-roles']">
                    {{"MANAGE_RIGHT_TO_USER" | translate}}
                </a>
                <a *ngIf="isManager"
                   mat-list-item
                   [routerLink]="['/rights/manage-family-users']">
                    {{ "MANAGE_FAMILY_USERS" | translate }}
                </a>
                <a *ngIf="isGroupAdmin || isAdmin"
                   mat-list-item
                   [routerLink]="['/rights/groups']">
                   {{ "MANAGE_GROUPS" | translate }}
                </a>
                <a *ngIf="isOversize"
                   mat-list-item
                   [routerLink]="['/certificates']">
                    {{"CERTIFICATES" | translate}}
                </a>
                <a *ngIf="!env.production"
                   mat-list-item
                   [routerLink]="['/dev-tools']">
                    {{"DEV_TOOLS" | translate}}
                </a>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-nav-list>
</div>
