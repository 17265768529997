import { Site } from 'src/app/models/site';
import * as SiteAction from '../actions/site.action';
import { createReducer, on, Action } from '@ngrx/store';
export interface State {
    data: Array<Site>;
    loading: boolean;
    loaded: boolean;
}

export const INIT_STATE: State = {
    data: [],
    loading: false,
    loaded: false,
};

const SitesReducer = createReducer(
    INIT_STATE,
    on(SiteAction.fetchAllSites, (state): State => ({ ...state, loaded: false })),
    on(SiteAction.successFetchAllSites, (state, { sites }): State => ({ ...state, loaded: true, data: sites })),
    on(SiteAction.successCreateSites, (state, { payload }): State => ({
        ...state,
        data: [...state.data, ...payload]
    })),
    on(SiteAction.mappingAllSitesCompanies, (state, { sites, companies }) => ({
        ...state,
        loaded: true,
        data: (() => {
            for (const site of sites) {
                site.company = companies.find((company) => company.id === site.companyId);
            }
            return sites;
        })()
    })),
    on(SiteAction.mappingSomeSitesCompanies, (state, { sites, companies }) => ({
        ...state,
        data: (() => {
            const allSites = [...state.data];
            for (const site of sites) {
                site.company = companies.find((company) => company.id === site.companyId);
                const siteIndexToUpdate = allSites.findIndex(_site => site.id === _site.id);
                if (siteIndexToUpdate > -1) {
                    allSites[siteIndexToUpdate] = site;
                } else {
                    allSites.push(site);
                }
            }
            return allSites;
        })()
    })),
    on(SiteAction.successDeleteSites, (state, { payload }) => ({
        ...state,
        data: (() => {
            const allSites = [...state.data];
            const arraySitesToDelete = payload;
            for (const site of arraySitesToDelete) {
                const siteIndex = allSites.findIndex(_site => site.id === _site.id);
                if (siteIndex > -1) {
                    allSites.splice(siteIndex, 1);
                }
            }
            return allSites;
        })()
    })),
    on(SiteAction.errorFetchAllSites, (state): State => ({ ...state }))
);

export function reducer(state: State | undefined, action: Action) {
    return SitesReducer(state, action);
}
