import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit, SecurityContext, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '../shared/font-awesome.module';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { combineLatest, distinctUntilChanged, filter, firstValueFrom, Observable, OperatorFunction, take, tap } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CacheService } from 'src/app/services/cache.service';
import { Store } from '@ngrx/store';
import { fetchAllParentFamilies } from 'src/app/store/actions/parent-family.action';
import { fetchAllFamilies } from 'src/app/store/actions/family.action';
import { fetchAllAssets } from 'src/app/store/actions/asset.actions';
import { fetchAllTrackers } from 'src/app/store/actions/tracker.action';
import { selectParentFamilyLoaded$ } from 'src/app/store/selectors/parent-family.selectors';
import { selectFamilyLoaded$ } from 'src/app/store/selectors/family.selectors';
import { selectAssetLoaded$ } from 'src/app/store/selectors/asset.selectors';
import { selectTrackerLoaded$ } from 'src/app/store/selectors/tracker.selectors';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatRadioModule } from '@angular/material/radio';
import { isEqual } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { fetchAllCompanies } from 'src/app/store/actions/company.actions';
import { fetchAllSites } from 'src/app/store/actions/site.action';
import { fetchAllZones } from 'src/app/store/actions/zone.action';
import { selectZonesLoaded$ } from 'src/app/store/selectors/zone.selector';
import { selectSiteLoaded$ } from 'src/app/store/selectors/site.selectors';
import { selectCompaniesLoaded$ } from 'src/app/store/selectors/company.selectors';
import { LoadParentFamilyRight } from 'src/app/store/actions/user-right.action';
import { LoadFamilyRight } from 'src/app/store/actions/familyRight.action';
import { selectUserRightLoaded$ } from 'src/app/store/selectors/user-right.selectors';
import { selectFamilyRightLoaded$ } from 'src/app/store/selectors/familyRight.selectors';

enum Source {
    tracker = 'tracker',
    asset = 'asset',
    rights = 'rights',
    kpi = 'kpi',
}

enum IssueType {
    cache = 'cache',
    generic = 'generic',
    other = 'other',
}

interface Issue {
    type: IssueType,
    index: number,
}

@Component({
    selector: 'app-troubleshooting',
    standalone: true,
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatProgressBarModule,
        MatRadioModule,
        MatStepperModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    templateUrl: './troubleshooting.component.html',
    styleUrl: './troubleshooting.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TroubleshootingComponent implements OnInit {
    private readonly fb = inject(FormBuilder);
    private readonly destroyRef = inject(DestroyRef);
    private readonly store = inject(Store);
    private readonly cd = inject(ChangeDetectorRef);
    private readonly sanitizer = inject(DomSanitizer);
    private readonly http = inject(HttpClient);
    private readonly translate = inject(TranslateService);
    private readonly cacheService = inject(CacheService);

    @ViewChild(MatStepper) stepper!: MatStepper;
    public readonly Source = Source;
    public readonly IssueType = IssueType;
    public resolutionInProgress = false;
    private readonly RESPONSES_TO_QUESTIONS: Partial<Record<`${Source}.${IssueType}`, Record<number, string>>> = {
        [`${Source.kpi}.${IssueType.generic}`]: {
            0: 'TROUBLESHOOTING.RESOLUTION.ANSWER.KPI.0',
        },
        [`${Source.tracker}.${IssueType.generic}`]: {
            0: 'TROUBLESHOOTING.RESOLUTION.ANSWER.TRACKER.0',
        },
        [`${Source.asset}.${IssueType.generic}`]: {
            0: 'TROUBLESHOOTING.RESOLUTION.ANSWER.ASSET.0',
        },
        [`${Source.rights}.${IssueType.generic}`]: {
            0: 'TROUBLESHOOTING.RESOLUTION.ANSWER.RIGHTS.0',
        },
    }

    public issues = signal<Issue[]>([], { equal: isEqual });

    public serviceNowLink: SafeUrl = this.sanitizer.sanitize(
        SecurityContext.URL,
        'https://airbus.service-now.com/nav_to.do?uri=service_offering.do?sys_id=a9103bec1b166450e833a713604bcb4b%26sysparm_view=sa_map_properties',
    );

    public resolutionProgress = signal<number>(0);
    public feedbackGiven = signal<boolean>(false);

    /** Step 1 */
    public sourceStep = this.fb.group({
        source: this.fb.control<Source | null>(null, Validators.required)
    });
    /** Step 2 */
    public issueStep = this.fb.group({
        issue: this.fb.control<Issue|null>(null, Validators.required)
    });
    /** Step 3 */
    public resolutionStep = this.fb.group({
        context: this.fb.control<boolean>(false, Validators.required),
    })

    ngOnInit(): void {
        this.sourceStep.controls.source.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            distinctUntilChanged(),
        ).subscribe(value => {
            const questionQuantity: Record<IssueType, number> = {
                [IssueType.cache]: 0,
                [IssueType.generic]: 0,
                [IssueType.other]: 0
            };
            const issues: Issue[] = [];
            switch (value) {
                case Source.asset:
                   questionQuantity[IssueType.cache] = 3;
                   questionQuantity[IssueType.generic] = 1;
                   break;
                case Source.rights:
                    questionQuantity[IssueType.cache] = 2;
                    questionQuantity[IssueType.generic] = 1;
                    break;
                case Source.tracker:
                    questionQuantity[IssueType.generic] = 1;
                    break;
                case Source.kpi:
                    questionQuantity[IssueType.generic] = 1;
            }
            for (let i = 0; i < questionQuantity[IssueType.cache]; i++) {
                issues.push({
                    type: IssueType.cache,
                    index: i
                });
            }
            for (let i = 0; i < questionQuantity[IssueType.generic]; i++) {
                issues.push({
                    type: IssueType.generic,
                    index: i
                });
            }
            this.issues.set(issues)
            this.cd.markForCheck();
        });
    }

    public onSelectedIndexChange(index: number) {
        switch (index) {
            case 0:
                this.issueStep.reset();
                this.feedbackGiven.set(false);
            // eslint-disable-next-line no-fallthrough
            case 1:
                this.resolutionStep.reset();
            // eslint-disable-next-line no-fallthrough
        }
        if (index === 3) {
            this.processResolution();
        }
    }

    private async processResolution() {
        if (this.issueStep.value.issue?.type === IssueType.cache) {
            this.resolutionInProgress = true;
            await this.cacheService.resetDatabase(true);
            await this._loadServicesWithContext();
            this.resolutionInProgress = false;
        }
    }

    private _loadServicesWithContext(): Promise<void> {
        const actionsDataToFetch = [
            fetchAllCompanies(),
            fetchAllSites(),
            fetchAllZones(),
            fetchAllParentFamilies(),
            fetchAllFamilies(),
            fetchAllAssets(),
            fetchAllTrackers(),
            new LoadFamilyRight(),
        ];
        const selectorsForLoaded = [
            selectCompaniesLoaded$,
            selectSiteLoaded$,
            selectZonesLoaded$,
            selectParentFamilyLoaded$,
            selectFamilyLoaded$,
            selectAssetLoaded$,
            selectTrackerLoaded$,
            selectFamilyRightLoaded$,
        ];
        actionsDataToFetch.forEach(action => this.store.dispatch(action));
        this.resolutionProgress.set(0);
        return new Promise<void>(
            (resolve, reject) => setTimeout(
                () => {
                    combineLatest(
                        selectorsForLoaded.map(
                            (selector, i) => this.store.select(selector).pipe(
                                filter(loaded => loaded),
                                tap(() => this.resolutionProgress.update(v => v + (100 / selectorsForLoaded.length)))
                            )
                        )
                    ).pipe(
                        filter(loadStatus => loadStatus.every(s => !!s)),
                        take(1),
                    ).subscribe({
                        complete: () => resolve(),
                        error: error => reject(error),
                    });
                }
            )
        );
    }

    public getSolutions(): string[] {
        const source = this.sourceStep.value.source;
        const issue = this.issueStep.value.issue;
        if (issue?.type === IssueType.generic) {
            return [this.RESPONSES_TO_QUESTIONS[`${source}.${IssueType.generic}`]?.[issue.index]];
        } else if (issue?.type === IssueType.other) {
            return ['TROUBLESHOOT.STEP3.SOLVING_SNOW'];
        }
        return [];
    }

    public feedback(positive: boolean): void {
        this.feedbackGiven.set(false);
        const { source } = this.sourceStep.value;
        const { issue } = this.issueStep.value;
        this.http.post(`${environment.API_ENDPOINT}/feedback`, {
            type: 'troubleshoot',
            positive,
            source,
            issue: {
                type: issue.type,
                index: issue.index,
                i18n: this.translate.instant(`TROUBLESHOOT.STEP2.${source.toUpperCase()}.${issue.type.toUpperCase()}.QUESTION${issue.index}`),
            },
        }).subscribe(
            () => this.feedbackGiven.set(true)
        );
    }
}
