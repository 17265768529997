import { Zone } from './../../models/zone';
import { errorFetchAllZones, errorUpdateZone, fetchAllZones, mapZonesFamilies, successUpdateZone } from '../actions/zone.action';
import { Action, createReducer, on } from '@ngrx/store';

export interface State {
    data: Array<Zone>;
    loading: boolean;
    loaded: boolean;
}

const initialState: State = {
    data: [],
    loading: false,
    loaded: false,
};

const zoneReducer = createReducer(
    initialState,
    on(fetchAllZones, (state): State => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    }),
    on(errorFetchAllZones, (state): State => {
        return {
            ...state,
            loading: false,
            loaded: true
        };
    }),
    on(mapZonesFamilies, (state, action) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
          action.zones.forEach(zone => {
            for (const scope in zone.associatedFamilies) {
                zone.associatedFamilies[scope] = zone.associatedFamilies[scope].map(
                    familyOfScope => ({
                      ...familyOfScope,
                      // Add the value family to the associated families of the zone
                      family: action.families.find(family => family.id === familyOfScope.familyId),
                    })
                  );
            }
            // Build the list of associatedFamiliesMapped from the previously added families
            zone.associatedFamiliesMapped = [...new Set(Object.values(zone.associatedFamilies).reduce(
              (p, families) => p.concat(families), []
            ))].map(
              family => family?.family
            ).filter(
              family => !!family
            );
            return zone;
          });
          action.zones.forEach(
            (actionZone: Zone) => {
              const stateZone = state.data.find(({ id }) => actionZone.id === id);
              if (stateZone) {
                Object.assign(actionZone, stateZone);
              } else {
                state.data.push(actionZone);
              }
            });
            return state.data;
        })()
      };
    }),
    on(successUpdateZone, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            data: (() => {
                const newState = JSON.parse(JSON.stringify(state.data));
                newState.splice(newState.findIndex(e => e.id === action.zone.id), 1, action.zone);
                return newState;
            })()
        };
    }),
    on(errorUpdateZone, (state): State => {
        return {
            ...state,
            loading: false,
            loaded: true
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return zoneReducer(state, action);
}
